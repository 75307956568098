import type { Plugin } from '@nuxt/types'
import type { ApolloQueryResult } from '@apollo/client/core/types'
import { onGlobalSetup, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api'
import type { UiNotification } from '~/composables/useUiNotification'
import { useCustomerStore } from '~/modules/customer/stores/customer'
import { useUser } from '~/modules/customer/composables/useUser'
import { useWishlist } from '~/modules/wishlist/composables/useWishlist'
import { useCart } from '~/modules/checkout/composables/useCart'
import { useCheckoutState } from '~/composables'
import { CheckoutStep } from '~/composables/useCheckoutState/enums/CheckoutStep'

export function hasGraphqlAuthorizationError (res: ApolloQueryResult<unknown>): boolean {
  return res?.errors
    ?.some(error => error?.extensions?.category === 'graphql-authorization') ?? false
}

// eslint-disable-next-line func-style
const plugin: Plugin = ({ app }) => {
  const customerStore = useCustomerStore(app.$pinia)

  onGlobalSetup(() => {
    const { $vsf } = useContext()
    const { load: loadUser, user } = useUser()
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist()
    const { load: loadCart, loadTotalQty: loadCartTotalQty, cart } = useCart()
    const {
      updateCheckoutInfo,
      resetCheckoutStorage,
      syncCheckoutState,
      activateStep
    } = useCheckoutState()

    const userDataFetched = ref<boolean>(false)

    async function prepareCommonUserData (): Promise<void> {
      await Promise.all([
        loadCartTotalQty()
      ])
    }

    async function prepareAuthorizedUserData (): Promise<void> {
      if ($vsf.$magento.config.state.getCustomerToken()) {
        customerStore.setIsLoggedIn(true)

        await Promise.all([
          loadUser(),
          loadWishlistItemsCount(),
          loadCart()
        ])
      }
    }

    onMounted(async () => {
      await Promise.all([
        prepareCommonUserData(),
        prepareAuthorizedUserData()
      ])
      userDataFetched.value = true
    })

    watch(cart, (newValue) => {
      if (!newValue?.items.length) {
        resetCheckoutStorage()
        syncCheckoutState()
        activateStep(CheckoutStep.contactInfo)
      }
    })

    watch(user, () => {
      updateCheckoutInfo()
    })

    watch(() => customerStore.isLoggedIn, (isLoggedIn) => {
      if (userDataFetched.value) {
        (app.router.app as any).refresh()
      }
    })
  })

  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res
    }
    customerStore.setIsLoggedIn(false)
    app.$vsf.$magento.config.state.removeCustomerToken()
    app.$vsf.$magento.config.state.removeCartId()
    app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol(''),
      message: app.i18n.t('You are not authorized, please log in.') as string,
      type: 'warning',
      icon: null,
      persist: true,
      title: null
    })

    void app.router.push(app.localePath('/'))

    return false
  })
}

// eslint-disable-next-line import/no-default-export
export default plugin
